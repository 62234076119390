<template>
  <section>
    404 error
  </section>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>